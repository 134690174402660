import React from "react"
import Layout from "@components/layout";
import 'tw-elements';

// Import SVG
import thanksSVG1 from '@images/thanks-icon.svg';

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/thanks-register.json";
import langJa from "@content/locales/ja/thanks-register.json";

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

export default function ThanksRegister( props ){

  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // Seo情報設定
  const pathName = 'thanks-register'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );
  
  return(
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 md:flex">
          <div className="md:w-[82%] text-center mx-auto h1_filed">
            <h1 className="md:text-2xl font-semibold text-xl">{postLang["thanks-txt1"]}<br></br>{postLang["thanks-txt2"]}</h1>
          </div>
        </div>
      </div>
      <div className="bg-[#F7F7F7] p-6 max-w-2xl md:mx-auto mx-10 rounded-xl mt-10 mb-[80px]">
        <div className="mx-auto max-w-[64px] pt-4"><img className="lazyload" data-src={thanksSVG1} alt="" /></div>
        <div className="max-w-xl mx-auto  pt-5 pb-5 md:max-w-5xl px-6  text-center">
          <p> {postLang["thanks-txt3"]}<br></br> {postLang["thanks-txt4"]}<br></br> {postLang["thanks-txt5"]} </p>
        </div>
      </div>
    </Layout>
  </>
  )
}
